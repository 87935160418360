.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 60px auto;
}

.container-details {
  display: flex;
  flex-direction: column;
  width: 1000px;
}

.title {
  font-size: 48px;
  color: #edf2f4;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .title{
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .container-details{
    align-items: center;
    justify-content: center;
    width: 90%;
  }
}
