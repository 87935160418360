.container-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px 0 120px 0;
}

.welcome {
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 20px;
}

.my-name {
  font-size: 60px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
}

.dev {
  padding: 16px 24px;
  color: #d90429;
  text-shadow: 0px 0px 10px #d90429;
  font-size: 36px;
  font-weight: 500;
  border-radius: 40px;
}

@media screen and (max-width: 600px) {
  .container-message {
    margin: 60px 0 60px 0;
  }
  .my-name {
    font-size: 40px;
  }
  .welcome {
    font-size: 30px;
  }
  .dev {
    font-size: 20px;
  }
}
