.Home {
  display: flex;
  flex-direction: column;
  position: relative;
}

.fondo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
