.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  margin: 60px auto;
}

.projects-title {
  font-size: 48px;
  color: #edf2f4;
  margin-bottom: 36px;
}

.projects__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .projects-title{
    font-size: 36px;
  }
}
