.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1c1d2b;
  padding: 0 20px;
}

.icons-c {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0 40px 0;
}

.icon-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.contact-icon {
  margin: 10px;
  width: 70px;
  height: 70px;
  color: #8d99ae;
}

.contact-title {
  font-size: 18px;
  color: #8d99ae;
}

.contact-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 900px;
}


.container-details-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #8d99ae;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .contact-details {
    flex-direction: column;
    width: 80%;
  }

  .container-details-contact {
    font-size: 14px;
  }

  .contact-icon{
    margin: 6px;
    width: 50px;
    height: 50px;
  }

  .icons-c{
    flex-direction: column;
  }
}