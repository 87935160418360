.experience {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid #8d99ae;
  margin: 20px 0px 0px 0px;
}

.info-experience {
  display: flex;
  flex-direction: column;
  width: 260px;
}

.info-experience h5 {
  font-size: 18px;
  font-weight: 500;
  color: #edf2f4;
  margin: 0px;
  text-transform: uppercase;
}

.academy {
  font-size: 16px;
  font-weight: 500;
  color: #d90429;
  margin-bottom: 6px;
}

.year {
  font-size: 14px;
  font-weight: 400;
  color: #edf2f4;
  margin: 0;
}

.experience p {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #edf2f4;
  margin: 0;
  width: 400px;
  justify-content: flex-end;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .info-experience{
    margin-right: 16px;
  }

  .info-experience h5{
    font-size: 16px;
  }

  .academy{
    font-size: 14px;
  }

  .year{
    font-size: 12px;
  }

  .experience p{
    font-size: 14px;
    width: 300px;
  }
}
