.card {
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  max-width: 300px;
  min-height: 320px;
  background-color: #1c1d2b;
  cursor: pointer;
  transition: 0.3s;
}

.card:hover {
  transform: scale(1.15);
  transition: 0.3s;
}

.card-image {
  width: 300px;
  height: 150px;
  border-radius: 10px 10px 0 0;
  background-color: #edf2f4;
}

.card-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  object-fit: contain;
}

.card-content {
  padding: 10px;
  text-align: center;
}

.card-title {
  font-size: 24px;
  font-weight: 600;
  color: #edf2f4;
  margin: 0 0 10px 0;
}

.card-description {
  font-size: 16px;
  font-weight: 400;
  color: #edf2f4;
  margin: 0;
}

@media screen and (max-width: 600px) {
  .card {
    max-width: 160px;
    margin: 10px;
  }
  .card-image {
    width: 160px;
    object-fit: cover;
  }

  .card-content {
    height: 60px;
  }

  .card-title {
    font-size: 16px;
  }

  .card-description {
    font-size: 12px;
  }
}
