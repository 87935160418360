.description-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  margin: 60px auto;
}

.description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 900px;
  color: #edf2f4;
}

.img-description {
  width: 350px;
  object-fit: cover;
  margin-right: 40px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.text-description {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.name {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #edf2f4;
}

.role {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #d90429;
}

.role-description {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

.list-li {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
}

.b {
  margin-right: 4px;
  color: #d90429;
  font-size: 18px;
  font-weight: 600;
}

.button-cv {
  border: none;
  background-color: #ef233c;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.3s ease-in-out;
  width: 180px;
}

@media screen and (max-width: 600px) {
  .description {
    flex-direction: column;
    width: 100%;
  }

  .img-description {
    width: 300px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .text-description {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .role-description{
    text-align: center;
    width: 90%;
  }

  .list{
    align-items: center;
  }
}
