.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-title {
  font-size: 48px;
  color: #edf2f4;
  margin-bottom: 36px;
}

.container-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.container-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  transition: 0.3s;
}

.container-icon:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.icon-title {
  font-size: 24px;
  color: #8d99ae;
}

.icon {
  margin: 10px;
  width: 100px;
  height: 100px;
  color: #8d99ae;
}

.icon-express {
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: #8d99ae;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2b2d42;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .icon-express {
    width: 50px;
    height: 50px;
    font-size: 12px;
  }

  .icon{
    margin: 6px;
    width: 50px;
    height: 50px;
  }

  .skills-title{
    font-size: 36px;
  }
}
