.Header {
  display: flex;
  padding: 0 20px;
  height: 80px;
  background-color: rgba(43, 45, 66, 0.7);
  margin-top: 28px;
  z-index: 10;
}

.container-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  width: 900px;
}

.link {
  text-decoration: none;
  color: #edf2f4;
  font-size: 20px;
  margin: 0 20px;
}

.link:hover {
  color: #ef233c;
}

.button-menu {
  display: none;
}

.div-menu {
  display: none;
}

.link-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.link-menu {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin: 20px;
}

@media screen and (max-width: 600px) {
  .div-menu {
    display: flex;
    flex-direction: column;
    background-color: rgba(43, 45, 66, 0.7);
    width: 100%;
    position: absolute;
    top: 78px;
    right: 0;
  }

  .button-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ef233c;
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
  }

  .link {
    display: none;
  }

  .container-header {
    width: 100%;
    justify-content: flex-start;
  }

  .Header {
    height: 50px;
  }
}
